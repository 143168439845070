import React from "react";
import { Box, Typography } from "@mui/material";
import {
  Facebook,
  WhatsApp,
  Instagram,
  LocalPhoneRounded,
} from "@mui/icons-material";

const Contact = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "10px",
        flexWrap: "wrap",
      }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
          padding: "20px",
          margin: "10px",
          minHeight: "100px",
          borderRadius: "10px",
          minWidth: "300px",
          maxWidth: "700px",
          boxShadow: 3,
          gap: "10px",
          height: "100%",
        }}>
        <Typography variant="h5" sx={{ marginBottom: "10px" }}>
          Contact Info:
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <LocalPhoneRounded />
          <Typography variant="body1">
            <a
              href="tel:+61420589786"
              style={{ textDecoration: "none", color: "inherit" }}>
              +61 420 589 786
            </a>
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
          padding: "20px",
          margin: "10px",
          minHeight: "100px",
          borderRadius: "10px",
          minWidth: "300px",
          maxWidth: "700px",
          boxShadow: 3,
          gap: "10px",
          height: "100%",
        }}>
        <Typography variant="h5">Social Media:</Typography>
        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Facebook
            sx={{ cursor: "pointer" }}
            onClick={() =>
              window.open("https://www.facebook.com/drivingskill", "_blank")
            }
          />
          <Typography variant="body1">Facebook</Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <WhatsApp
            sx={{ cursor: "pointer" }}
            onClick={() => window.open("https://wa.me/+61420589786", "_blank")}
          />
          <Typography variant="body1">WhatsApp</Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Instagram
            sx={{ cursor: "pointer" }}
            onClick={() =>
              window.open(
                "https://www.instagram.com/city.driving.school?igsh=eHk1ZXc3ZnM3bTN1",
                "_blank"
              )
            }
          />
          <Typography variant="body1">Instagram</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Contact;
