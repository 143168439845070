import { useState } from "react";
import { Box, Card, Typography, Button, Link, Tab, Tabs } from "@mui/material";
import { Check, WhatsApp } from "@mui/icons-material";
import { styled } from "@mui/system";
const StyledTabs = styled(Tabs)({
  "& .MuiTabs-indicator": {
    display: "none",
  },
});

const StyledTab = styled(Tab)({
  textTransform: "none",
  fontWeight: "bold",
  fontSize: "1rem",
  color: "black",
  backgroundColor: "#F9D951",
  borderRadius: "50px",
  border: "none",
  "&.Mui-selected": {
    color: "#000",
    backgroundColor: "#fff",
    border: "2px solid #000",
    variant: "contained",
  },
});

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const Packages = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const automaticCourses = [
    {
      prices: "$75*",
      title: "Basic Plan",
      features: [
        "1 hour normal lesson",
        "Automatic Driving Lesson",
        "Practical sessions",
      ],
    },
    {
      prices: "$140*",
      title: "Express Lesson",
      features: [
        "90 Mins lesson before the test",
        "Mock Test Scenario",
        "Prepare for the test",
      ],
    },
    {
      prices: "$240*",
      title: "Driving Test Package",
      features: [
        "Modern Fleet of Cars",
        "1 hour Lesson before the test",
        "Mock Test Scenario",
      ],
    },
    {
      prices: "$355*",
      title: "Advance Plan",
      features: [
        "5 lessons, each lasting 1 hour",
        "Automatic Driving Lesson",
        "Practical sessions",
      ],
    },
    {
      prices: "$675*",
      title: "Legendary Plan",
      features: [
        "10 lessons, each lasting 1 hour.",
        "Automatic Driving Lesson",
        "Practical sessions",
      ],
    },
  ];

  const manualCourses = [
    {
      prices: "$90*",
      title: "Basic Plan",
      features: [
        "1 hour normal lesson",
        "Manual Driving Lesson",
        "Practical sessions",
      ],
    },
    {
      prices: "$160*",
      title: "Express Lesson",
      features: [
        "90 Mins lesson before the test",
        "Mock Test Scenario",
        "Prepare for the test",
      ],
    },
    {
      prices: "$290*",
      title: "Driving Test Package",
      features: [
        "Modern Fleet of Cars",
        "1 hour Lesson before the test",
        "Mock Test Scenario",
      ],
    },
    {
      prices: "$425*",
      title: "Advance Plan",
      features: [
        "5 lessons, each lasting 1 hour",
        "Manual Driving Lesson",
        "Practical sessions",
      ],
    },
    {
      prices: "$810*",
      title: "Legendary Plan",
      features: [
        "10 lessons, each lasting 1 hour.",
        "Manual Driving Lesson",
        "Practical sessions",
      ],
    },
  ];

  return (
    <Box
      sx={{
        padding: "10px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}>
      <Typography variant="h4">Packages</Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
          padding: "10px",
          borderRadius: "50px",
          backgroundColor: "#F9D851",
        }}>
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example">
          <StyledTab label="Automatic" />
          <StyledTab label="Manual" />
        </StyledTabs>
      </Box>

      <TabPanel value={value} index={0}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
          }}>
          {automaticCourses.map((course, i) => (
            <Card
              sx={{
                margin: "10px",
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "self-start",
                boxShadow: 7,
                border: "2px solid",
                borderRadius: "20px",
                borderColor: "primary.main",
                width: "305px",
                gap: "1rem",
              }}
              key={i}>
              <Typography variant="h6">{course.title}</Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5rem",
                }}>
                <Typography variant="body">From</Typography>
                <Typography variant="h4">{course.prices}</Typography>
              </Box>
              {course.features.map((feature, index) => (
                <Box
                  key={index}
                  sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
                  <Check />
                  <Typography variant="body1">{feature}</Typography>
                </Box>
              ))}
              <Link
                href="https://wa.me/+61420589786"
                style={{ width: "100%", textDecoration: "none" }}
                target="_blank"
                rel="noopener noreferrer">
                <Button
                  style={{ width: "100%", textDecoration: "none" }}
                  variant="contained"
                  color="primary">
                  <WhatsApp /> Enquire Now
                </Button>
              </Link>
            </Card>
          ))}
        </Box>
        <Typography variant="h6">
          *Prices may vary with Driving test time distance and lesson hours
        </Typography>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
          }}>
          {manualCourses.map((course, i) => (
            <Card
              sx={{
                margin: "10px",
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "self-start",
                boxShadow: 7,
                border: "2px solid",
                borderRadius: "20px",
                borderColor: "primary.main",
                width: "305px",
                gap: "1rem",
              }}
              key={i}>
              <Typography variant="h6">{course.title}</Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5rem",
                }}>
                <Typography variant="body">From</Typography>
                <Typography variant="h4">{course.prices}</Typography>
              </Box>
              {course.features.map((feature, index) => (
                <Box
                  key={index}
                  sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
                  <Check />
                  <Typography variant="body1">{feature}</Typography>
                </Box>
              ))}
              <Link
                href="https://wa.me/+61420589786"
                style={{ width: "100%", textDecoration: "none" }}
                target="_blank"
                rel="noopener noreferrer">
                <Button
                  style={{ width: "100%", textDecoration: "none" }}
                  variant="contained"
                  color="primary">
                  <WhatsApp /> Enquire Now
                </Button>
              </Link>
            </Card>
          ))}
        </Box>
        <Typography variant="h6">
          *Prices may vary with Driving test time distance and lesson hours
        </Typography>
      </TabPanel>
    </Box>
  );
};

export default Packages;
