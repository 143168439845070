import React from "react";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import Contact from "./Contact";
import ContactUsForm from "./ContactUsForm";

const Footer = () => {
  return (
    <Box sx={{ backgroundColor: "primary.main" }}>
      <Box
        className="footer"
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          alignItems: "center",
          flexWrap: "wrap",
          gap: "10px",
          padding: "20px",
          borderRadius: "10px",
          boxShadow: 3,
        }}>
        <Typography variant="h5">
          <Link to={"/t&c"}>Terms & Conditions</Link>
        </Typography>
        <ContactUsForm />
        <Contact />
      </Box>
    </Box>
  );
};

export default Footer;
