import { Box, useMediaQuery, useTheme } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import Packages from "./Packages";
import WhyChoseUs from "./WhyChoseUs";
import About from "./About";
import LicConversionText from "./LicConversionText";
import Footer from "./Footer";
const Home = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const items = [
    {
      image: "file.jpg",
      alt: "Home Image 0",
    },
    {
      image:
        "https://citydrivingschool.s3.ap-southeast-2.amazonaws.com/home_image1.jpg",
      alt: "Home Image 1",
    },
    {
      image:
        "https://citydrivingschool.s3.ap-southeast-2.amazonaws.com/home_image0.jpg",
      alt: "Home Image 2",
    },
    {
      image:
        "https://citydrivingschool.s3.ap-southeast-2.amazonaws.com/home_image3.jpg",
      alt: "Home Image 3",
    },
    {
      image:
        "https://citydrivingschool.s3.ap-southeast-2.amazonaws.com/home_image4.jpg",
      alt: "Home Image 4",
    },
    {
      image:
        "https://citydrivingschool.s3.ap-southeast-2.amazonaws.com/home_image6.jpg",
      alt: "Home Image 6",
    },
  ];

  return (
    <Box>
      <Box sx={{ maxHeight: isSmallScreen ? "60vh" : "80vh" }}>
        <Carousel indicators={false}>
          {items.map((item, i) => (
            <Item key={i} item={item} />
          ))}
        </Carousel>
        <LicConversionText />
        <Packages />
        <WhyChoseUs />
        <About />
        <Footer />
      </Box>
    </Box>
  );
};

function Item(props) {
  return (
    <Box className="image-container">
      <img
        src={props.item.image}
        alt={props.item.alt}
        title={props.item.name}
        style={{
          maxWidth: "100%",
          maxHeight: "100%",
          objectFit: "cover",
        }}
        className="full-width-image"
      />
    </Box>
  );
}

export default Home;
