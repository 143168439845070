import React from "react";
import { Box, Typography, Button, TextField } from "@mui/material";

const commonTextFieldStyles = {
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#000",
    },
    "&:hover fieldset": {
      borderColor: "#000",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#000",
    },
  },
  "& .MuiInputLabel-root": {
    color: "#000",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "#000",
  },
  borderRadius: "5px",
};

const ContactUsForm = () => {
  const [result, setResult] = React.useState(null);

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending....");
    const formData = new FormData(event.target);

    formData.append("access_key", "d2737921-f931-42e8-a0bc-a8250c510fb9");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    if (data.success) {
      setResult("Form Submitted Successfully");
      event.target.reset();
    } else {
      console.log("Error", data);
      setResult(data.message);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}>
      <Typography
        variant="h4"
        align="center"
        sx={{ fontWeight: 700, marginBottom: "2rem" }}>
        Contact Us
      </Typography>
      <Box
        component="form"
        onSubmit={onSubmit}
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#fff",
          maxWidth: "600px",
          width: "100%",
          padding: "2rem",
          borderRadius: "10px",
          boxShadow: "0 8px 8px rgba(0, 0, 0, 0.1)",
        }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "1rem",
            marginBottom: "1rem",
          }}>
          <TextField
            name="name"
            label="Name"
            variant="outlined"
            required
            fullWidth
            sx={commonTextFieldStyles}
          />
          <TextField
            name="email"
            label="Email"
            type="email"
            variant="outlined"
            required
            fullWidth
            sx={commonTextFieldStyles}
          />
        </Box>
        <TextField
          name="message"
          label="Message"
          multiline
          rows={4}
          variant="outlined"
          required
          fullWidth
          sx={commonTextFieldStyles}
        />
        {result && (
          <Typography
            variant="body1"
            sx={{
              marginBottom: "1rem",
              color: result.includes("Successfully") ? "green" : "red",
            }}>
            {result}
          </Typography>
        )}
        <Button
          type="submit"
          variant="contained"
          sx={{
            backgroundColor: "#000",
            color: "#fff",
            padding: "0.75rem",
            marginTop: "1rem",
            fontSize: "1rem",
            "&:hover": {
              backgroundColor: "primary",
            },
          }}>
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default ContactUsForm;
