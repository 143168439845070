import React, { useState } from "react";
import {
  AppBar,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  useTheme,
  Divider,
  SwipeableDrawer,
  Toolbar,
} from "@mui/material";
import {
  Facebook,
  WhatsApp,
  Menu,
  LocalPhoneRounded,
  Instagram,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

function Nav() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const menuItems = [
    { text: "Home", onClick: () => navigate("/") },
    { text: "Packages", onClick: () => navigate("/packages") },
    { text: "Services", onClick: () => navigate("/services") },
    { text: "About", onClick: () => navigate("/about") },
    // { text: "Contact", onClick: () => navigate("/contact") },
  ];

  const socialItems = [
    {
      icon: <Facebook />,
      onClick: () =>
        window.open("https://www.facebook.com/drivingskill", "_blank"),
    },
    {
      icon: <Instagram />,
      onClick: () =>
        window.open(
          "https://www.instagram.com/city.driving.school?igsh=eHk1ZXc3ZnM3bTN1",
          "_blank"
        ),
    },
    {
      icon: <WhatsApp />,
      onClick: () => window.open("https://wa.me/+61420589786", "_blank"),
    },
    {
      icon: (
        <>
          <LocalPhoneRounded /> &nbsp; Call Now{" "}
        </>
      ),
      onClick: () => {
        window.location.href = "tel:+61420589786";
      },
    },
  ];

  return (
    <AppBar color="primary" position="relative">
      <Box
        sx={{
          padding: "10px",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
        <img src="logo.svg" alt="logo" height={75} />
        {isSmallScreen ? (
          <>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerOpen}>
              <Menu />
            </IconButton>
            <SwipeableDrawer
              anchor="right"
              open={drawerOpen}
              onClose={handleDrawerClose}
              onOpen={handleDrawerOpen}
              sx={{
                width: "100%",
                flexShrink: 0,
              }}>
              <Toolbar />
              <Divider />
              <List>
                {menuItems.map((item, index) => (
                  <ListItem button key={index} onClick={item.onClick}>
                    <ListItemText color="primary" primary={item.text} />
                  </ListItem>
                ))}
              </List>
              <Divider />
            </SwipeableDrawer>
            <Box sx={{ p: 2, display: "flex", justifyContent: "space-around" }}>
              {socialItems.map((item, index) => (
                <IconButton key={index} onClick={item.onClick}>
                  {item.icon}
                </IconButton>
              ))}
            </Box>
          </>
        ) : (
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              alignItems: "center",
              flexWrap: "wrap",
            }}>
            {menuItems.map((item, index) => (
              <Button
                variant="contained"
                color="primary"
                sx={{ margin: "10px", borderRadius: "10px" }}
                onClick={item.onClick}
                startIcon={item.icon}
                key={index}>
                {item.text}
              </Button>
            ))}
            <Box sx={{ p: 2, display: "flex", justifyContent: "space-around" }}>
              {socialItems.map((item, index) => (
                <IconButton
                  sx={{ color: "black" }}
                  key={index}
                  onClick={item.onClick}>
                  {item.icon}
                </IconButton>
              ))}
            </Box>
          </Box>
        )}
      </Box>
    </AppBar>
  );
}

export default Nav;
